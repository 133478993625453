/**
 * @ngdoc directive
 * @name flowForm
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display the form the user uses to complete a flow step.
 * @usage
 * ```
    <flow-form ng-show="$ctrl.flow.form" flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-form>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 * * showForm: display the form only if this is true
 */
import { FormGen } from '@Client/@types/formGen';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';
import angular, {
  IFormController,
  IQService,
  IScope,
  ITimeoutService,
  IWindowService
} from 'angular';
import RunnerFlowService from '../runner.flow/runner.flow.service';
import StepService, {
  FormValidationConfig
} from '@Client/runner.services/step.service';
import { FormFieldConditionAction } from '@Shared.Angular/flowingly.services/flowingly.constants';
import IFormattedFlow from '@Client/interfaces/IFormattedFlow';
import { Guid } from '@Shared.Angular/@types/guid';
import IFormattedStep from '@Client/interfaces/IFormattedStep';

class CustomError {
  private stack?: string;

  constructor(message?) {
    if (typeof (Error as any).captureStackTrace === 'function') {
      (Error as any).captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

class UserCancelledException extends CustomError {}
class ValidationException extends CustomError {}

class FlowFormComponentController {
  static $inject = [
    '$window',
    '$scope',
    '$q',
    'intercomService',
    'notificationService',
    'pubsubService',
    'dialogService',
    'sessionService',
    'fgFileListService',
    'flowListManager',
    'flowApiService',
    'dateService',
    'flowinglyConstants',
    'flowinglyModelUtilityService',
    '$timeout',
    'runnerFlowsFormatter',
    'runnerFlowService',
    'runnerCardService',
    'stepService',
    'appInsightsService'
  ];

  private flow: IFormattedFlow;
  private stepForForm: IFormattedStep;
  private isMobile;
  private _disableFooterButtons;

  private interval: any = 0;
  private formErrors: any = {
    file: false,
    table: false
  };
  private completedApprovals: any;
  private numberOfCompletedApprovals: any;
  private numberOfApprovalsRequired: any;
  private numberOfApprovalsSelected: any;
  private numberOfApproversRequiredType: any;
  private flowForm: any;
  private subscriberId = 'runnerCardController';
  // currently only used to convert the pubsub pattern into
  // a promise so we can chain. The best case scenario would be to
  // avoid the pubsub pattern for now as it is currently adding an extra
  // layer of unecessary complexity
  //                                                      - Cassey
  private deferredFileUploadPromise: angular.IDeferred<any>[];

  constructor(
    private $window: IWindowService,
    private $scope: IScope,
    private $q: IQService,
    private intercomService: SharedAngular.IntercomService,
    private notificationService: SharedAngular.NotificationService,
    private pubsubService: SharedAngular.PubSubService,
    private dialogService: SharedAngular.DialogService,
    private sessionService: SharedAngular.SessionService,
    private fgFileListService: FormGen.FgFileListService,
    private flowListManager: FlowListManager,
    private flowApiService: FlowApiService,
    private dateService: DateService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private flowinglyModelUtilityService: SharedAngular.FlowinglyModelUtilityService,
    private $timeout: ITimeoutService,
    private runnerFlowsFormatter: RunnerFlowsFormatterService,
    private runnerFlowService: RunnerFlowService,
    private runnerCardService: RunnerCardService,
    private stepService: StepService,
    private appInsightService: SharedAngular.AppInsightsService
  ) {
    // a number of these are being used as callbacks, but I'm too tired to figure
    // out which functions are which, so I'm just going to blindly bind all of them
    // to this
    //                                                              - Cassey
    this.sendCard = this.sendCard.bind(this);
    this.callBoomiProcess = this.callBoomiProcess.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.getDynamicActorsAndApproversThatShouldBeSelected =
      this.getDynamicActorsAndApproversThatShouldBeSelected.bind(this);
    this.getModelerNodeIdForStep = this.getModelerNodeIdForStep.bind(this);
    this.getStepForModelerNode = this.getStepForModelerNode.bind(this);
    this.getStepForStepId = this.getStepForStepId.bind(this);
    this.getFlowSchemaForStep = this.getFlowSchemaForStep.bind(this);
    this.getNextModelerNodeIds = this.getNextModelerNodeIds.bind(this);
    this.isApprovalTaskRejected = this.isApprovalTaskRejected.bind(this);
    this.addNodeIdsForNodes = this.addNodeIdsForNodes.bind(this);
    this.findMatchedGate = this.findMatchedGate.bind(this);
    this.compute = this.compute.bind(this);
    this.getDecisionFieldValueForExclusiveGateway =
      this.getDecisionFieldValueForExclusiveGateway.bind(this);
    this.getNextNodeIdsForExclusiveGateway =
      this.getNextNodeIdsForExclusiveGateway.bind(this);
    this.multipleApproverDialog = this.multipleApproverDialog.bind(this);
    this.multipleApproversDialog = this.multipleApproversDialog.bind(this);
    this.dynamicActorsDialog = this.dynamicActorsDialog.bind(this);
    this.completeStep = this.completeStep.bind(this);
    this.isElementInViewport = this.isElementInViewport.bind(this);
    this.shouldNotSave = this.shouldNotSave.bind(this);
    this.saveFormProgress = this.saveFormProgress.bind(this);
    this.disableFooterButtons = this.disableFooterButtons.bind(this);
    this.enableFooterButtons = this.enableFooterButtons.bind(this);
    this.fileRemovalComplete = this.fileRemovalComplete.bind(this);
    this.setFileError = this.setFileError.bind(this);
    this.clearFileError = this.clearFileError.bind(this);
    this.clearTableError = this.clearTableError.bind(this);
    this.setTableError = this.setTableError.bind(this);
    this.settingOffsettoForm = this.settingOffsettoForm.bind(this);

    if (this.flow?.FinalisedDate === null) {
      $window.onbeforeunload = () => {
        //returning true prompts the user with a stndard browser dialog.
        //it asks them to confirm they want to leave /refresh the page as data will be lost
        //You might ask, why not just call saveFormProgress() here? Good question.
        //I tried that first, but for some reason despite being called, it did not actually save.
        //Did not have time to investigate why, so this is a quicker solution.
        if (this.flowForm && this.flowForm.$dirty) {
          return true;
        } else {
          return null;
        }
      };
    }

    this.initializeDeferredFileUploadPromise();
  }

  $onChanges(changes) {
    this.enableFooterButtons();
    // need clear the validation states, fix FLOW-2215 Done button still inconsistent
    this.formErrors = {
      file: false,
      table: false
    };

    if (changes.stepForForm) {
      // initialise form data then the saved value can load properly
      this.stepService.initialiseFormData(this.stepForForm);
      this.completedApprovals = this.stepForForm.CompletedApprovals;
      this.numberOfCompletedApprovals = this.completedApprovals.length;
      this.numberOfApprovalsRequired =
        this.stepForForm.numberOfApproversRequired;
      this.numberOfApprovalsSelected =
        this.stepForForm.numberOfApproversSelected;
      this.numberOfApproversRequiredType =
        this.stepForForm.numberOfApproversRequiredType;
      this.interval = this.$timeout(this.settingOffsettoForm);

      if (this.flowForm) {
        // Reset the form because it is reused between parallel steps.
        this.flowForm.$setPristine();
      }
    }
  }

  $onInit() {
    this.pubsubService.subscribe(
      'FORM_TABLE_VALID',
      this.clearTableError,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FORM_TABLE_INVALID',
      this.setTableError,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_STARTED',
      this.disableFooterButtons,
      this.subscriberId
    ); // YUCK!!!
    this.pubsubService.subscribe(
      'FILEUPLOAD_FILE_ERROR',
      this.setFileError,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_REMOVAL_STARTED',
      this.disableFooterButtons,
      this.subscriberId
    ); // YUCK!!!
    this.pubsubService.subscribe(
      'FILEUPLOAD_FILE_VALID',
      this.clearFileError,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_COMPLETED',
      this.enableFooterButtons,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_REMOVAL_COMPLETED',
      this.fileRemovalComplete,
      this.subscriberId
    );
    this.pubsubService.subscribe(
      'FILEUPLOAD_UPLOAD_FAILED',
      this.enableFooterButtons,
      this.subscriberId
    );
  }

  $onDestroy() {
    this.$window.onbeforeunload = null;
    //avoid nuisance calllbacks and memory leaks
    this.pubsubService.unsubscribeAll(this.subscriberId);
    this.saveFormProgress();
    this.fgFileListService.removeAllFileControls();
  }

  public onSubmitCb() {
    this.appInsightService.startEventTimer('stepFormSubmitted');
    this.appInsightService.startEventTimer('stepFormTransitionStarted');
    const formData = angular.copy(this.stepForForm.$data);
    this.runnerCardService.formatFieldsWithCustomDBUserTypeToObject(
      formData,
      this.stepForForm.Schema
    );
    const fileUploadData = this.runnerCardService.createFileUploadData(
      this.stepForForm.Id,
      this.stepForForm.Schema
    );

    return this.sendCard(
      this.stepForForm.Id,
      this.stepForForm.FlowId,
      formData,
      this.stepForForm.Schema,
      this.flowForm,
      fileUploadData
    );
  }

  public sendCard(
    stepId,
    flowId,
    data,
    schema,
    form: IFormController,
    fileUploadData?
  ) {
    let validatedData: FormValidationConfig;
    if (this.runnerFlowService.isBusy == true) {
      return this.$q.reject('Saving in progress...');
    }

    const pr = this.$q
      .resolve()
      .then(() => {
        validatedData = this.stepService.validateForm(
          data,
          schema,
          form,
          fileUploadData,
          true,
          stepId
        );
        if (!this.isFormValid(validatedData, flowId)) {
          throw new ValidationException();
        } else {
          this.runnerFlowsFormatter.encodeFlowFormData(
            validatedData.formData,
            schema.fields,
            false
          );
        }

        const visibleFieldsFormData = JSON.parse(JSON.stringify(data));
        validatedData.formData.forEach((item) => {
          if (item?.fieldConditionStatus === FormFieldConditionAction.Hide) {
            delete visibleFieldsFormData[item.key];
          }
        });

        return this.getDynamicActorsAndApproversThatShouldBeSelected(
          stepId,
          flowId,
          visibleFieldsFormData
        );
      })
      .then((result) => {
        const hasDynamicActors =
          result.dynamicActors &&
          result.dynamicActors.length > 0 &&
          result.dynamicActors.some((da) => da.dynamicActors.length !== 0);
        const hasMultipleApprovers =
          result.multipleApprovers && result.multipleApprovers.length > 0;
        const completeStepPayload: CompleteStepPayload = {
          form,
          stepId,
          flowId,
          validatedData,
          assignedDynamicActors: null,
          selectedApprovers: null
        };

        let payloadBuilderPromises = this.$q.resolve(completeStepPayload);

        if (hasDynamicActors) {
          payloadBuilderPromises = payloadBuilderPromises.then(
            (completeStepPayload) => {
              const nextModelerNodeIds = this.getNextModelerNodeIds(
                stepId,
                this.flow,
                data
              );
              const modelerNodeId = this.getModelerNodeIdForStep(
                stepId,
                this.flow
              );

              const timedDynamicActorsDialog =
                this.appInsightService.timeUserActivityForEvent(
                  this.dynamicActorsDialog,
                  ['stepFormSubmitted', 'stepFormTransitionStarted']
                );
              return timedDynamicActorsDialog(
                flowId,
                result.dynamicActors,
                nextModelerNodeIds,
                modelerNodeId
              ).then((assignedDynamicActors) => {
                if (
                  !assignedDynamicActors ||
                  assignedDynamicActors.length === 0
                ) {
                  throw new UserCancelledException();
                } else {
                  completeStepPayload.assignedDynamicActors =
                    assignedDynamicActors;
                }
                return completeStepPayload;
              });
            }
          );
        }

        if (hasMultipleApprovers) {
          payloadBuilderPromises = payloadBuilderPromises.then(
            (completeStepPayload) => {
              const nextModelerNodeIdsWithoutCrossingMergeGw =
                this.getNextModelerNodeIds(stepId, this.flow, data, true);

              const timedMultipleApproversDialog =
                this.appInsightService.timeUserActivityForEvent(
                  this.multipleApproversDialog,
                  ['stepFormSubmitted', 'stepFormTransitionStarted']
                );

              return timedMultipleApproversDialog(
                form,
                flowId,
                stepId,
                validatedData,
                result.multipleApprovers,
                nextModelerNodeIdsWithoutCrossingMergeGw
              ).then((selectedApprovers: unknown[]) => {
                if (!selectedApprovers || selectedApprovers.length === 0) {
                  throw new UserCancelledException();
                } else {
                  completeStepPayload.selectedApprovers = selectedApprovers;
                }

                return completeStepPayload;
              });
            }
          );
        }

        return payloadBuilderPromises;
      })
      .then(this.completeStep)
      .catch((e) => {
        if (e instanceof UserCancelledException) {
          console.info('[User] Cancelled dynamic/multiple actor selection');
        } else if (e instanceof ValidationException) {
          console.info('[System] Failed validation');
          this.notificationService.showErrorToast(
            'Oops, a validation error has occurred, please review the fields on the current step for more details.'
          );
        } else {
          console.error(e, stepId, flowId, data, schema, form);
          this.notificationService.showErrorToast(
            'Sorry, there is an issue when trying to complete this step.'
          );
        }
      })
      .then(() => {
        //Make sure the DB has saved the step information so that SaveInProgress is not
        //called while this transaction is taking place
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return this.$timeout(() => {}, 300);
      });

    this.runnerFlowService.setBusyWith(pr);

    return pr;
  }

  public callBoomiProcess(process, request, value) {
    this.flowApiService.callBoomiProcess(process, request, value);
  }

  private isFormValid(validatedData, flowId) {
    const isValid = this.stepService.validateFormData(validatedData);

    if (isValid) {
      // [FLOW-6978] It appears that the this.formErrors is not built to cater for multiple fields and is causing issues in case of conditionally hidden fields.
      // Handling form conditionally hidden fields in a slightly different way, also the tradtional validation is working as expected so doesnt look like
      // it requires the below "Last line to defence for validating the form"
      if (
        validatedData.hasConditionallyHiddenInvalidTableField === true ||
        validatedData.hasConditionallyHiddenInvalidFileUploadField === true
      ) {
        return true;
      }

      //Last line of defence for validating the form
      if (
        !validatedData.form.$valid ||
        this.formErrors.file ||
        this.formErrors.table ||
        this.fgFileListService.getAnyFileListInvalid(flowId)
      ) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  private getDynamicActorsAndApproversThatShouldBeSelected(
    stepId,
    flowId,
    data
  ) {
    const nextModelerNodeIds = this.getNextModelerNodeIds(
      stepId,
      this.flow,
      data
    );
    if (!nextModelerNodeIds) {
      return this.$q.resolve({
        dynamicActors: null,
        multipleApprovers: null
      });
    }

    const modelerNodeId = this.getModelerNodeIdForStep(stepId, this.flow);
    const options = {
      modelerNodeIds: JSON.stringify(nextModelerNodeIds),
      flowId: flowId,
      modelerNodeId: modelerNodeId,
      maxNumberOfUsersToShow: this.flowinglyConstants.maxNumberOfUsersToShow,
      data: JSON.stringify(data)
    };
    return this.flowApiService
      .getApproversAndDynamicActorsForNodes(options)
      .then(JSON.parse);
  }

  private getModelerNodeIdForStep(stepId, flow) {
    if (flow && flow.Steps) {
      const step = flow.Steps.find((s) => s.Id === stepId);
      return step && step.ModelerNodeId;
    }
    return null;
  }

  private getStepForModelerNode(nodeId, flow) {
    if (flow && flow.Steps) {
      const steps = flow.Steps.filter((s) => s.ModelerNodeId === nodeId).sort(
        (a, b) => (a.CompletedDate < b.CompletedDate ? 1 : -1)
      );
      if (steps && steps.length > 0) {
        return steps[0];
      }
    }
    return null;
  }

  private getStepForStepId(stepId, flow) {
    if (flow && flow.Steps) {
      const step = flow.Steps.find((s) => s.Id === stepId);
      return step;
    }
    return null;
  }

  private getFlowSchemaForStep(stepId, flow) {
    const step = flow.Steps.find((s) => s.Id === stepId);
    if (step && !!step.InComponentSchemaId) {
      const componentSchema = flow.FlowComponentSchemas.find(
        (s) => s.SchemaId === step.InComponentSchemaId
      );
      if (componentSchema && componentSchema.FlowSchema)
        return JSON.parse(componentSchema.FlowSchema);
    }

    return JSON.parse(flow.ParsedSchema);
  }

  private getNextModelerNodeIds(stepId, flow, data, stopAtMergeGateway?) {
    if (this.isApprovalTaskRejected(stepId, flow, data)) return null;

    const modelerNodeId = this.getModelerNodeIdForStep(stepId, flow);
    const flowSchema = this.getFlowSchemaForStep(stepId, flow);
    const nodeKey = this.flowinglyModelUtilityService.getNodeKeyById(
      flowSchema.nodeDataArray,
      modelerNodeId
    );
    const nextModelNodes =
      this.flowinglyModelUtilityService.getNextNodesForNode(
        nodeKey,
        flowSchema.nodeDataArray,
        flowSchema.linkDataArray
      );
    const modelerNodeIds = [];
    this.addNodeIdsForNodes(
      nextModelNodes,
      flowSchema,
      data,
      flow,
      modelerNodeIds,
      stopAtMergeGateway
    );

    return modelerNodeIds;
  }

  private isApprovalTaskRejected(stepId, flow, data) {
    const step = this.getStepForStepId(stepId, flow);
    if (
      step.StepType === this.flowinglyConstants.taskType.APPROVAL ||
      step.StepType === this.flowinglyConstants.taskType.PARALLEL_APPROVAL ||
      step.StepType === this.flowinglyConstants.taskType.SEQUENTIAL_APPROVAL
    ) {
      const approvalField = step.Fields.find(
        (f) =>
          f.Type.toLowerCase() ===
          this.flowinglyConstants.stepTypeName.APPROVAL.toLowerCase()
      );
      const approvalValue = data[approvalField.Name];
      if (
        approvalValue != null &&
        approvalValue !== this.flowinglyConstants.approvalTaskOptions.APPROVE
      ) {
        return true;
      }
    }

    return false;
  }

  private addNodeIdsForNodes(
    modelNodes,
    flowSchema,
    data,
    flow,
    modelerNodeIds,
    stopAtMergeGateway?
  ) {
    (modelNodes || []).forEach((node) => {
      let nextNodes = [];
      switch (node.category) {
        case this.flowinglyConstants.nodeCategory.ACTIVITY:
        case this.flowinglyConstants.nodeCategory.COMPONENT:
          modelerNodeIds.push(node.id);
          break;
        case this.flowinglyConstants.nodeCategory.DIVERGE_GATEWAY:
        case this.flowinglyConstants.nodeCategory.CONVERGE_GATEWAY:
          if (!stopAtMergeGateway) {
            nextNodes = this.flowinglyModelUtilityService.getNextNodesForNode(
              node.key,
              flowSchema.nodeDataArray,
              flowSchema.linkDataArray
            );
            this.addNodeIdsForNodes(
              nextNodes,
              flowSchema,
              data,
              flow,
              modelerNodeIds,
              stopAtMergeGateway
            );
          }
          break;
        case this.flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY:
          this.getNextNodeIdsForExclusiveGateway(
            node.key,
            flowSchema,
            data,
            flow,
            modelerNodeIds
          );
          break;
        case this.flowinglyConstants.nodeCategory.EVENT:
        default:
          break;
      }
    });
  }

  private findMatchedGate(gates, decisionFieldValue, isCustomDb) {
    gates = gates.sort((g1, g2) => g1.order - g2.order);
    let gate = gates.find((g) => {
      if (
        (!g.isDefault || g.isDefault === 'false') &&
        g.condition &&
        g.condition.value != null
      ) {
        switch (g.condition.type) {
          case this.flowinglyConstants.formFieldType.CHECKBOX: {
            const value = g.condition.value === 'true' ? true : false;
            return decisionFieldValue === value;
          }
          case this.flowinglyConstants.formFieldType.RADIO_BUTTON_LIST:
          case this.flowinglyConstants.formFieldType.SELECT_LIST: {
            if (!decisionFieldValue) {
              return false;
            }
            let condition;
            if (isCustomDb) {
              condition = g.condition.name;
            } else {
              condition = g.condition.value;
            }

            if (decisionFieldValue.includes('text":')) {
              const dfvParts = decisionFieldValue.split(':');
              const dfv = dfvParts[2]
                .trim()
                .replaceAll('"', '')
                .replace('}', '');
              return (
                condition
                  .split('|')
                  .map((value) => value && value.trim())
                  .indexOf(dfv) > -1
              );
            } else {
              return (
                condition
                  .split('|')
                  .map((value) => value && value.trim())
                  .indexOf(decisionFieldValue.trim()) > -1
              );
            }
          }
          case this.flowinglyConstants.formFieldType.NUMBER:
          case this.flowinglyConstants.formFieldType.CURRENCY:
          default:
            return this.compute(
              decisionFieldValue,
              g.condition.name,
              g.condition.value
            );
        }
      }
      return false;
    });

    if (!gate) {
      gate = gates.find((g) => g.isDefault === 'true');
    }
    return gate;
  }

  private compute(leftValue, operator, rightValue) {
    if (
      isNaN(leftValue) ||
      leftValue == null ||
      leftValue === '' ||
      isNaN(rightValue) ||
      rightValue == null ||
      rightValue === ''
    )
      return false;

    leftValue = +leftValue;
    rightValue = +rightValue;

    switch (operator) {
      case '=':
        return leftValue === rightValue;
      case '!=':
        return leftValue !== rightValue;
      case '>':
        return leftValue > rightValue;
      case '>=':
        return leftValue >= rightValue;
      case '<':
        return leftValue < rightValue;
      case '<=':
        return leftValue <= rightValue;
      default:
        return false;
    }
  }

  private getDecisionFieldValueForExclusiveGateway(
    gatewayNode,
    flowSchema,
    flow,
    data
  ) {
    const decisionFieldId = gatewayNode.gateway.fieldId;
    if (data && data[decisionFieldId] != null) {
      // decision field is in current step data
      return data[decisionFieldId];
    }

    const decisionNode = this.flowinglyModelUtilityService.getNodeByKey(
      flowSchema.nodeDataArray,
      gatewayNode.selectedNodeKey
    );
    const decisionStep = this.getStepForModelerNode(decisionNode.id, flow);
    if (decisionStep && decisionStep.Fields) {
      const field = decisionStep.Fields.find((f) => f.Name === decisionFieldId);
      return field && field.Value;
    }
    return null;
  }

  private getNextNodeIdsForExclusiveGateway(
    nodeKey,
    flowSchema,
    data,
    flow,
    nodeIds
  ) {
    try {
      const gatewayNode = this.flowinglyModelUtilityService.getNodeByKey(
        flowSchema.nodeDataArray,
        nodeKey
      );
      const gateway = gatewayNode.gateway;
      const isCustomDb = !!gateway.dbName;
      const decisionFieldValue = this.getDecisionFieldValueForExclusiveGateway(
        gatewayNode,
        flowSchema,
        flow,
        data
      );
      const matchedGate = this.findMatchedGate(
        gateway.gates || gateway.Gates,
        decisionFieldValue,
        isCustomDb
      );
      if (matchedGate) {
        const nextNode = this.flowinglyModelUtilityService.getNodeByKey(
          flowSchema.nodeDataArray,
          matchedGate.routeToKey
        );
        nextNode &&
          this.addNodeIdsForNodes([nextNode], flowSchema, data, flow, nodeIds);
      }
    } catch (e) {
      console.error(
        `${e.message} in method getNextNodeIdsForExclusiveGateway`,
        nodeKey,
        flowSchema,
        data,
        flow,
        nodeIds
      );
      throw e;
    }
  }

  private multipleApproverDialog(
    form,
    flowId,
    stepId,
    validatedData,
    multipleApproversForModal,
    totalCount,
    currentIndex,
    selectedApprovers,
    nextModelerNodeIdsWithoutCrossingMergeGw
  ) {
    const nodeId = multipleApproversForModal[currentIndex].nodeId;
    const modelerNodeId = multipleApproversForModal[currentIndex].modelerNodeId;
    return this.dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.form/runner.flow.multiple-approvers.dialog.tmpl.html',
        controller: 'selectApproversController',
        appendClassName: 'ngdialog-theme-plain w-500',
        scope: this.$scope,
        closeByEscape: false,
        closeByDocument: false,
        data: {
          currentStepId: stepId,
          flowId: flowId,
          nodeId: nodeId,
          stepType: multipleApproversForModal[currentIndex].stepType,
          stepName: multipleApproversForModal[currentIndex].stepName,
          numberOfApproversRequired:
            multipleApproversForModal[currentIndex].numberOfApproversRequired,
          maxNumberOfApproversRequired:
            multipleApproversForModal[currentIndex]
              .maxNumberOfApproversRequired || 0,
          numberOfApproversRequiredType:
            multipleApproversForModal[currentIndex]
              .numberOfApproversRequiredType,
          totalCount: totalCount,
          currentIndex: currentIndex
        }
      })
      .then((approvers) => {
        if (this.dialogService.isCloseModalWithCancelAction(approvers)) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return false;
        }

        if (approvers != null) {
          selectedApprovers.push({
            nodeId: nodeId,
            approvers: approvers,
            isNextNode: nextModelerNodeIdsWithoutCrossingMergeGw.some(
              (id) => id === modelerNodeId
            )
          });
          if (++currentIndex < totalCount) {
            return this.multipleApproverDialog(
              form,
              flowId,
              stepId,
              validatedData,
              multipleApproversForModal,
              totalCount,
              currentIndex,
              selectedApprovers,
              nextModelerNodeIdsWithoutCrossingMergeGw
            );
          } else return true;
        }
        return false;
      });
  }

  private multipleApproversDialog(
    form,
    flowId,
    stepId,
    validatedData,
    multipleApproversForModal,
    nextModelerNodeIdsWithoutCrossingMergeGw
  ) {
    const selectedApprovers = [];
    return this.multipleApproverDialog(
      form,
      flowId,
      stepId,
      validatedData,
      multipleApproversForModal,
      multipleApproversForModal.length,
      0,
      selectedApprovers,
      nextModelerNodeIdsWithoutCrossingMergeGw
    ).then((result) => {
      if (result) {
        return selectedApprovers;
      } else return null;
    });
  }

  private dynamicActorsDialog(
    flowId,
    dynamicActorsForModal,
    nextModelerNodeIds,
    modelerNodeId
  ) {
    return this.dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.form/runner.flow.dynamic-actor.dialog.tmpl.html',
        controller: 'dynamicActorDialogController',
        appendClassName: 'ngdialog-theme-plain w-500',
        scope: this.$scope,
        data: {
          dynamicActorsForSteps: dynamicActorsForModal,
          flowId: flowId,
          nextModelerNodeIds: nextModelerNodeIds,
          modelerNodeId: modelerNodeId
        }
      })
      .then((assignedDynamicActors) => {
        if (
          this.dialogService.isCloseModalWithCancelAction(assignedDynamicActors)
        ) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return null;
        }

        return assignedDynamicActors;
      });
  }

  private completeStep({
    stepId,
    flowId,
    validatedData,
    assignedDynamicActors,
    selectedApprovers
  }: CompleteStepPayload) {
    this.disableFooterButtons();

    const stepFiles = this.fgFileListService.getFilesForStep(stepId);
    const dataToSave = this.stepService.appendTimezoneOffsetToDates(
      validatedData.formData,
      this.stepForForm.Schema
    );
    return this.flowApiService
      .completeTask(
        stepId,
        flowId,
        dataToSave,
        stepFiles,
        assignedDynamicActors,
        selectedApprovers
      )
      .then(
        (response) => {
          if (response.data.Success === true) {
            // Only carry on if task was able to be completed - note we would have already
            // refreshed the flow lists at this point
            this.fgFileListService.removeFileControlsForFlowStep(
              flowId,
              stepId
            );
            if (this.flowForm) {
              this.flowForm.$setPristine(); // set form to pristine then ready for next step as use same form name
            }
            this.notificationService.showSuccessToast('Step completed', 4000);

            const formFiles = this.fgFileListService.getForm();
            if (formFiles && !formFiles.isUndefined) {
              formFiles.model.state.$setPristine();
            }

            this.intercomService.trackEvent('Step Completed', {
              'flow name': this.flow.Name,
              'step name': this.stepForForm.Name
            });
          } else if (
            response.status === 200 &&
            response.data.ErrorCode === '400'
          ) {
            this.notificationService.showErrorToast(
              response.data.ErrorMessage,
              4000
            );
            this.enableFooterButtons();
          } else if (
            response.status !== 200 ||
            response.data.Success === false
          ) {
            this.notificationService.showErrorToast('Task failed', 4000);
          }
          this.appInsightService.trackMetricIfTimerExist(
            'stepFormSubmitted',
            this.getStepFormPropsForAppInsights()
          );
        },
        (response) => {
          if (response.data.Message.indexOf('AntiXss') > -1)
            this.enableFooterButtons();
        }
      );
  }

  private isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private shouldNotSave() {
    return (
      !this.flowForm || // html has not initialized
      this.flowForm.$pristine || // form is unchanged
      this.runnerFlowService.isBusy || // form is busy saving or something
      this.sessionService.getUser() === undefined || // no user in session?
      this.stepForForm.IsWaitingForYou === false ||
      this.stepForForm.IsWaitingForYou === 0
    ); // step not assigned to you
  }

  private saveFormProgress() {
    if (this.shouldNotSave()) {
      return;
    }

    //The user was half way through the form and moved somewhere else
    //save the form to the server
    const formData = this.stepService.getFormDataWithoutValidating(
      this.stepForForm
    );

    if (formData && formData.length > 0) {
      this.runnerFlowsFormatter.encodeFlowFormData(
        formData,
        this.stepForForm.Schema.fields,
        true
      );
      const dataToSave = this.stepService.appendTimezoneOffsetToDates(
        formData,
        this.stepForForm.Schema
      );

      this.flowApiService.saveFormProgress(
        this.flow.Id,
        this.stepForForm.Id,
        dataToSave,
        this.fgFileListService.getFilesForStep(this.stepForForm.Id)
      );
      this.flowListManager.updateFlowProgress(
        this.stepForForm.FlowId,
        this.stepForForm.Id,
        formData
      );

      this.stepForForm.LocalUpdatedDate = this.dateService.utcToLocal(
        new Date()
      );
    }
  }

  private initializeDeferredFileUploadPromise() {
    if (!this.deferredFileUploadPromise) {
      this.deferredFileUploadPromise = [];
    }
  }

  private disableFooterButtons() {
    const deferred = this.$q.defer();
    this.initializeDeferredFileUploadPromise();
    this.deferredFileUploadPromise.push(deferred);
    this.runnerFlowService.setBusyWith(deferred.promise);
  }

  private enableFooterButtons() {
    this.initializeDeferredFileUploadPromise();
    this.deferredFileUploadPromise.forEach((deferred) => {
      deferred.resolve();
      this.deferredFileUploadPromise = null;
    });
  }

  private fileRemovalComplete() {
    this.enableFooterButtons();
    this.saveFormProgress();
  }

  private setFileError(event, data) {
    if (data == null || data.fileControlId == null) {
      return;
    }

    // Only set the form file error to true when the event is for a field in the selected step.
    // Otherwise Runner can report validation errors incorrectly for parallel steps.
    if (
      this.stepForForm != null &&
      this.stepForForm.Schema != null &&
      this.stepForForm.Schema.fields != null
    ) {
      const matchingField = this.stepForForm.Schema.fields.find(
        (field) => field.name === data.fileControlId
      );
      if (matchingField != null) {
        this.fgFileListService.setFileListInvalid(
          data.fileControlId,
          this.stepForForm.Id,
          data.flowInstanceId,
          true
        );
        this.formErrors.file = true; // Prevent form submit.
      }
    }
  }

  private clearFileError(event, data) {
    this.formErrors.file = false;
    this.fgFileListService.setFileListInvalid(
      data.fileControlId,
      this.stepForForm.Id,
      data.flowInstanceId,
      false
    );
  }
  private clearTableError(event, data) {
    this.formErrors.table = false;
  }
  private setTableError(event, data) {
    this.formErrors.table = true;
  }

  private settingOffsettoForm() {
    if (angular.element('#flowForm_' + this.stepForForm.FlowId) == undefined) {
      this.interval = this.$timeout(this.settingOffsettoForm);
      return;
    }
    this.$timeout.cancel(this.interval);
    const offSetTop = document
      .getElementById('flowForm_' + this.stepForForm.FlowId)
      .getBoundingClientRect().top;
    const scrollHeight = angular.element(document).height();
    const scrollPosition =
      angular.element(window).height() + angular.element(window).scrollTop();
    if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
      if (offSetTop < 0) {
        window.scrollTo(0, scrollHeight / 2);
      } else {
        window.scrollTo(0, angular.element(window).scrollTop() - offSetTop);
      }
    }
  }

  private getStepFormPropsForAppInsights() {
    const { flowinglyConstants, flow, stepForForm } = this;
    const stepTypeName =
      flowinglyConstants.stepTypeName[
        flowinglyConstants.taskType[stepForForm.StepType]
      ];
    const props = {
      flowIdentifier: flow.FlowIdentifier,
      stepName: stepForForm.Name,
      stepType: stepForForm.StepType,
      stepTypeName
    };
    return props;
  }
}
class FlowFormComponent implements angular.IComponentOptions {
  bindings = {
    flow: '<',
    stepForForm: '<',
    isMobile: '<' //show mobile view if set
  };
  templateUrl =
    'Client/runner.flow/runner.flow.form/runner.flow.form.component.tmpl.html';
  controllerAs = '$ctrl';
  controller = FlowFormComponentController;
}

angular
  .module('flowingly.runner.flow')
  .component('flowForm', new FlowFormComponent());

export type CompleteStepPayload = {
  form;
  stepId: Guid;
  flowId: Guid;
  validatedData: FormValidationConfig;
  assignedDynamicActors: unknown[];
  selectedApprovers: unknown[];
};
