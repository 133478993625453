import angular, { IHttpPromiseCallbackArg, IQService } from 'angular';
import RunnerFlowService from './runner.flow.service';
import IFormattedFlow from '../../interfaces/IFormattedFlow';
import { IStateParamsService, IStateProvider } from 'angular-ui-router';
import { SharedAngular } from '@Client/@types/sharedAngular';

///
/// This state displays flows that the user can initiaite
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flow').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.flow', {
    url: 'flows/{flowId}?showComments&flowModelId&stepTask',
    params: { title: 'Flow Details', searchJump: false },
    controller: 'runnerFlowController',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.flow/runner.flow/runner.flow.tmpl.html',
    resolve: {
      flow: [
        '$stateParams',
        '$q',
        'flowModelApiService',
        'sessionService',
        'runnerFlowService',
        'guidService',
        function (
          $stateParams: IStateParamsService,
          $q: IQService,
          flowModelApiService: SharedAngular.FlowModelApiService,
          sessionService: SharedAngular.SessionService,
          runnerFlowService: RunnerFlowService,
          guidService: SharedAngular.GuidService
        ) {
          runnerFlowService.resetBusy();

          const flowId = $stateParams.flowId;
          if (flowId == guidService.empty()) {
            const flowModelId = $stateParams.flowModelId;

            return $q
              .all([
                flowModelApiService.getFlowModelById(flowModelId),
                sessionService.getUser()
              ])
              .then(([flowModel, currentUser]) => {
                const placeholder =
                  flowModel.customPlaceholder != null &&
                  flowModel.customPlaceholder != ''
                    ? flowModel.customPlaceholder
                    : 'Enter a descriptive subject';
                return <IFormattedFlow>{
                  FlowModelId: flowModelId,
                  FlowId: guidService.empty(),
                  FlowIdentifier: 'FLOW-',
                  MomentDue: '',
                  DueDate: '',
                  Subject: '...',
                  CurrentStepsHeader: 'Enter Subject',
                  Name: flowModel.name,
                  StartedByName: currentUser.fullName,
                  PercentageComplete: 0,
                  WaitingForName: currentUser.fullName,
                  CustomPlaceholder: placeholder,

                  Id: null,
                  Category: null,
                  CategoryId: null,
                  FlowSchema: null,
                  ParsedSchema: null,
                  StartedByUserId: null,
                  StartedDate: null,
                  FinalisedDate: null,
                  FinalisedReason: null,
                  IsStartedByYou: null,
                  IsFlowModelOwner: null,
                  CommentCount: null,
                  IsWaitingForYou: null,
                  NumberOfCompletedSteps: null,
                  NumberOfNotCompletedSteps: null,
                  Steps: null,
                  WaitingForDate: null,
                  BulkStartId: null,
                  CCUsers: null,
                  IsFlowModelPublishedToUser: null,
                  FlowComponentSchemas: null,
                  IsObsolete: null,
                  FlowVersionNumber: null,
                  RequestedByUserId: null,
                  RequestedByUser: null,
                  LatestSchemaVersion: null,
                  Description: null,
                  IsFinalised: null,
                  FinalisedInLocalFormat: null,
                  DueDateInLocalFormat: null,
                  MomentStartedFullDate: null,
                  MomentStarted: null,
                  WhoStarted: null,
                  MomentFinalised: null,
                  MomentFinalisedFullDate: null,
                  MomentFinalisedInLocalFormat: null,
                  MomentWaitingFor: null,
                  MomentWaitingForLabel: null,
                  IsDeleted: null,
                  IsOverdue: null,
                  FilesCount: null
                };
              })
              .catch((response) => {
                return response.status === 403
                  ? 'permission denied'
                  : response.statusText;
              });
          } else {
            return runnerFlowService
              .getFlowById(flowId)
              .catch((response: IHttpPromiseCallbackArg<null>) => {
                $stateParams.flowRequestErrorStatus = response.status;
              });
          }
        }
      ]
    }
  });
}
