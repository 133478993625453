/**
 * @ngdoc service
 * @name flowApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching flow data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * We flatten the responses from the server.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { IUserBasicInfo } from '@Client/interfaces/user.interface';
import IPublishedFlowModelCategory from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/publishedFlowModelCategory';
import { FieldFiles } from '@Shared.FormGen/angular-form-gen/form/files/file.listService';
import IFormInput from '@Shared.Angular/@types/core/contracts/queryModel/card/formInput';
import { IFlowForUserPascalCase } from '@Shared.Angular/@types/core/contracts/queryModel/flows/flowForUser';
import { Guid } from '@Shared.Angular/@types/guid';

export interface IFlowsForUserDataModel {
  FlowStates: string[];
  FlowModels: IFlowModelForUser[];
  GroupedFlows: IGroupedFlowsForUser[];
}

export interface IFlowModelForUser {
  Id: string;
  Name: string;
  FlowCategory: string;
  FlowCategoryId: string;
}

export interface IGroupedFlowsForUser {
  FlowCategory: string;
  FlowCategoryId: string;
  Flows: IMapFlowForGetFlows[];
  GroupName: string;
  CategoryTree?: IPublishedFlowModelCategory;
}

export interface IMapFlowForGetFlows {
  FlowId: string;
  FlowModelId: string;
  Name: string;
  Subject: string;
  StartedByName: string;
  StartedDate: string;
  FinalisedDate: string;
  FinalisedReason: string;
  DueDate: string;
  CommentCount: number;
  FilesCount: number;
  CurrentStep: string;
  CurrentStepsHeader: string;
  WaitingForName: string;
  WaitingForDate: string;
  PercentageComplete: number;
  CCUsers: string;
  WaitingFor: string;
  SortingField: string;
  FlowIdentifier: string;
  FlowCategory: string;
  FlowCategoryId: string;
  RequestedByUserId: string;
  RequestedByUser: IUserBasicInfo;
  IsConfidential: boolean;
}

export interface IStepReassignment {
  reassignType: string;
  reassignedToUserId: string;
  reassignedFromUserOrGroupId: string;
  stepTaskId: string;
  reason: string;
  flowId: string;
  stepId: string;
}

export interface IWebhookOverride {
  stepId: string;
  flowId: string;
  userId: string;
  reason: string;
}

export default class FlowApiService {
  public static $inject = [
    '$http',
    'APP_CONFIG',
    'sessionService',
    'runnerCardService'
  ];

  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private sessionService: SharedAngular.SessionService,
    private runnerCardService: RunnerCardService
  ) {}

  public completeTask(
    stepId: Guid,
    flowId: Guid,
    formData: IFormInput[],
    files: FieldFiles[],
    assignedDynamicActors,
    selectedApprovers
  ) {
    const cardData = JSON.stringify(
      this.runnerCardService.addFileIdsToForm(formData, files)
    );
    return this.$http
      .post(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/completeTask', {
        UserId: this.getUserId(),
        FlowInstanceId: flowId,
        StepId: stepId,
        cardData: cardData,
        isNewFlow: false,
        assignedDynamicActors: assignedDynamicActors,
        selectedApprovers: selectedApprovers
      })
      .then((response) => {
        return response;
      });
  }

  public nudgeFlowWaitingOnActor(stepNudgeHistory) {
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'nudge/step/',
      stepNudgeHistory
    );
  }

  public getStepNudgeHistoryList(noSpinner, stepId) {
    return this.$http
      .get<IResponseData>(`${this.APP_CONFIG.apiBaseUrl}nudge/step/${stepId}`, {
        noSpinner: noSpinner
      })
      .then((response) => {
        return response.data.dataModel;
      });
  }

  public getFlowById(flowId: Guid, noSpinner: boolean) {
    return this.$http
      .get<IFlowForUserPascalCase>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/flowsV2?flowId=' +
          flowId,
        { noSpinner: noSpinner }
      )
      .then((response) => {
        return response.data;
      });
  }

  public getApproversAndDynamicActorsForNodes(options) {
    return this.$http
      .post<IResponseData>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/approversAndDynamicActorsForNodes',
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  public getFlowsImin(status, onlyStartedByMe, startDate, endDate) {
    const userId = this.getUserId();
    return this.$http
      .get<IUResponseData<IFlowsForUserDataModel>>(
        `${this.APP_CONFIG.apiBaseUrl}workflowinstance/getflows?userId=${userId}&flowStatus=${status}&startedByMeOnly=${onlyStartedByMe}&waitingForYouOnly=false&startDate=${startDate}&endDate=${endDate}`,

        { noSpinner: false }
      )
      .then((response) => {
        return response.data.DataModel;
      });
  }

  public getFlowsTodo() {
    return this.$http
      .get<IFlowsForUserDataModel>(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/todo',
        {
          noSpinner: false
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  public getFlowSearchResults(searchTerm) {
    const userId = this.getUserId();
    return this.$http
      .get<IUResponseData>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/search?userId=' +
          userId +
          '&term=' +
          encodeURIComponent(searchTerm),
        { noSpinner: false }
      )
      .then((response) => {
        return response.data.DataModel;
      });
  }

  private saveFormPending = false;
  public saveFormProgress(flowId, stepId, formData, files: FieldFiles[]) {
    if (this.saveFormPending) {
      return Promise.resolve();
    }

    const cardData = JSON.stringify(
      this.runnerCardService.addFileIdsToForm(formData, files)
    );
    this.saveFormPending = true;
    return this.$http
      .post<void>(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/saveStepProgress',
        {
          StepId: stepId,
          UserId: this.getUserId(),
          FlowInstanceId: flowId,
          cardData: cardData
        },
        {
          noSpinner: true
        }
      )
      .then(angular.noop)
      .finally(() => (this.saveFormPending = false));
  }

  public callBoomiProcess(process, request, value) {
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'workflowinstance/boomi',
      { Process: process, Request: request, Value: value },
      {
        noSpinner: true
      }
    );
  }

  public startFlow(flowId, subject, assignedActorId, ccActors) {
    //start a flow with specified flow id and the subject user has entered
    return this.$http
      .post<IResponseData>(this.APP_CONFIG.apiBaseUrl + 'workflowinstance', {
        UserId: this.getUserId(),
        FlowId: flowId,
        Subject: subject,
        AssignedActorId: assignedActorId,
        CCActors: ccActors
      })
      .then((response) => {
        return response.data.dataModel;
      });
  }

  public bulkStartFlow(
    flowModelId,
    subject,
    actors,
    ccActors,
    assignedActorId,
    shouldAppendNameToSubject?: boolean,
    excludeActors?: Guid[]
  ) {
    const url = this.APP_CONFIG.apiBaseUrl + 'workflowinstance/bulkStart';
    const data = {
      FlowModelId: flowModelId,
      Subject: subject,
      ActorsToStartFlowFor: actors,
      CCActors: ccActors,
      AssignedActorId: assignedActorId,
      ShouldAppendNameToSubject: shouldAppendNameToSubject,
      ExcludeActors: excludeActors
    };

    return this.$http.post(url, data).then((response) => {
      return response.data;
    });
  }

  public withdrawFlow(data) {
    const request = {
      FlowInstanceId: data.flowId,
      CancellationComment: data.comment,
      EntitiesToNotify: data.selectedActors
    };

    return this.$http
      .post<IUResponseData>(
        `${this.APP_CONFIG.apiBaseUrl}workflowinstance/withdraw`,
        request
      )
      .then((response) => {
        return response.data.Success;
      });
  }

  public deleteFlow(flowId: Guid) {
    const url = `${this.APP_CONFIG.apiBaseUrl}workflowinstance/flows/${flowId}`;

    return this.$http.delete(url).then((response) => response);
  }

  public reassignStep(data: IStepReassignment) {
    return this.$http
      .post<IUResponseData>(
        this.APP_CONFIG.apiBaseUrl + 'delegation/reassignTaskToUser',
        data
      )
      .then((response) => {
        return response.data;
      });
  }

  private getUserId() {
    return this.sessionService.getUser().id;
  }

  public getFlowSupportDetails(flowId) {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/support/' + flowId)
      .then((response) => {
        return response.data;
      });
  }

  public addStepTask(data) {
    return this.$http
      .post(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/addStepTask', data)
      .then((response) => {
        return response;
      });
  }

  public updateStepTaskStatus(stepTaskId, status) {
    return this.$http
      .put(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/steptask/' +
          stepTaskId +
          '/status/' +
          status,
        undefined
      )
      .then((response) => {
        return response;
      });
  }

  public updateStepTaskApproval(stepTaskId, approverUserId, approved, comment) {
    const request = {
      stepTaskId: stepTaskId,
      approverUserId: approverUserId,
      approved: approved,
      comment: comment
    };

    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/approval',
        request
      )
      .then((response) => {
        return response;
      });
  }

  public cancelStepTask(data) {
    const request = {
      UserId: this.getUserId(),
      StepTaskId: data.stepTaskId,
      CancellationComment: data.comment,
      EntitiesToNotify: data.selectedActors
    };

    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/cancel',
        request
      )
      .then(
        (response) => {
          if (response.status === 200) {
            return true;
          } else {
            return false;
          }
        },
        () => {
          return false;
        }
      );
  }

  public getStepTask(stepTaskId) {
    return this.$http
      .get(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/' + stepTaskId
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      });
  }

  public overrideProcessingIntegration(data: IWebhookOverride) {
    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/overrideIntegrationState',
        data
      )
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('flowingly.runner.services')
  .service('flowApiService', FlowApiService);

export type FlowApiServiceType = InstanceType<typeof FlowApiService>;
