/**
 * @ngdoc directive
 * @name flowContainer
 * @module flowingly.runner.flow
 * @description  This component is used to gruop the content that is displayed when the flow is expanded..
 * @usage
 * ```
     <flow-container ng-show="$ctrl.expand" flow="$ctrl.flow"  on-comments-click="$ctrl.OnCommentsClick()" is-mobile="$ctrl.isMobile"></flow-container>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 * * userId: userid of the logged in user (SMEG)
 */

import { FormGen } from '@Client/@types/formGen';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';
import angular, {
  ILocationService,
  IQService,
  IRootScopeService,
  ITimeoutService
} from 'angular';
import RunnerFlowService from '../runner.flow/runner.flow.service';
import StepService from '@Client/runner.services/step.service';
import IFormattedFlow from '@Client/interfaces/IFormattedFlow';
import IStepForUserPascalCase from '@Shared.Angular/@types/core/contracts/queryModel/flows/stepForUser';

class FlowContainerController implements angular.IController {
  public static $inject = [
    '$rootScope',
    '$q',
    '$timeout',
    'lodashService',
    'avatarService',
    'flowApiService',
    'fgFileListService',
    'runnerFlowsFormatter',
    'runnerFlowService',
    'flowinglyDiagramService',
    'goService',
    'pubsubService',
    '$location',
    'flowinglyConstants',
    'appInsightsService',
    'stepService'
  ];

  public constructor(
    private $rootScope: IRootScopeService,
    private $q: IQService,
    private $timeout: ITimeoutService,
    private lodashService: Lodash,
    private avatarService: SharedAngular.AvatarService,
    private flowApiService: FlowApiService,
    private fgFileListService: FormGen.FgFileListService,
    private runnerFlowsFormatter: RunnerFlowsFormatterService,
    private runnerFlowService: RunnerFlowService,
    private flowinglyDiagramService: SharedAngular.FlowinglyDiagramService,
    private goService: GoJS,
    private pubsubService: SharedAngular.PubSubService,
    private $location: ILocationService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private appInsightsService: SharedAngular.AppInsightsService,
    private stepService: StepService
  ) {}

  // from bindings
  public flow: IFormattedFlow;

  // actual vars
  private selectedStep = {} as IStepForUserPascalCase;
  private changingStep = false;
  private disableFooterButtonsboolean = false;
  private showDoneButton: boolean;
  public displaySelectedStepTaskDetails = false;
  private selectedStepTask: any = {};

  private subscriberId = 'flowContainer';
  public $onChanges() {
    this.initialize();
  }

  public $onDestroy(): void {
    this.pubsubService.unsubscribeAll(this.subscriberId);
  }

  private hasStarted() {
    return this.runnerFlowService.hasStarted;
  }

  public selectedStepIsProcessingIntegration() {
    if (
      this.selectedStep != null &&
      this.selectedStep.IntegrationState != null
    ) {
      return (
        this.selectedStep.IntegrationState.State ===
        this.flowinglyConstants.stepIntegrationState.Processing
      );
    }
    return false;
  }

  private initialize() {
    const { avatarService, lodashService, $rootScope } = this;

    this.flow.StartedByUserAvatarUrl = avatarService.getAvatarUrl(
      this.flow.StartedByUserId
    );
    if (this.flow.Steps) {
      lodashService.forEach(this.flow.Steps, (step) => {
        step.ActorAssignedAvatarUrl = avatarService.getAvatarUrl(
          step.ActorAssignedId
        );
        step.CompletedByAvatarUrl = avatarService.getAvatarUrl(
          step.CompletedById
        );

        if (step.IsSelectedStep) {
          this.selectedStep = step;
          this.injectDynamicDropDownsOptions();
          this.dynamicallySelectStepTask();
        }

        // [FLOW-5585] Reorder Step Tasks if the step is not completed. If completed
        // then that means this component is used for displaying history. Don't have to apply
        // sorting in history.
        if (!step.IsCompleted && step.StepTasks != null) {
          step.StepTasks = this.runnerFlowsFormatter.reorderStepTasks(
            step.StepTasks
          );
        }
      });
    }

    switch ($rootScope.previousRoute) {
      case '/flowsin':
        if (this.flow.IsWaitingForYou) {
          this.showDoneButton = this.haveIncompleteSteps();
        }
        break;

      case '/flowstodo':
        this.showDoneButton = this.haveIncompleteSteps();
        break;

      default:
        if (this.flow.IsWaitingForYou) {
          this.showDoneButton = this.haveIncompleteSteps();
        }
    }

    this.pubsubService.subscribe(
      'STEP_TASK_CANCELLED',
      (event, data) => {
        this.resetStepTaskSelection(data);
      },
      this.subscriberId
    );

    this.pubsubService.subscribe(
      'STEP_TASK_UPDATED',
      (event, stepTask) => {
        if (stepTask != null && this.selectedStepTask != null) {
          if (
            this.displaySelectedStepTaskDetails &&
            stepTask.Id === this.selectedStepTask.Id
          ) {
            this.stepTaskSelectionChanged(stepTask);
          }
        }
      },
      this.subscriberId
    );

    // Subscribe to the step complete to clear any selected step task so that when the flow
    // is progressed to next step the selection is not retained.
    this.pubsubService.subscribe(
      'SIGNALR_RUNNER_COMPLETE_STEP',
      (event, data) => {
        this.clearStepTaskSelection();
      },
      this.subscriberId
    );

    this.scrollToTop();

    // flow could be loaded either from a link, or by tapping a flow in a list, or by refresh browser window
    const flowIdentifier = this.flow?.FlowIdentifier || '';
    this.appInsightsService.trackMetricIfTimerExist(
      'flowLoadFromExternalLink',
      { flowIdentifier }
    );
    this.appInsightsService.trackMetricIfTimerExist('flowSelectedFromList', {
      flowIdentifier
    });
    this.appInsightsService.trackMetricIfTimerExist(
      'stepFormTransitionStarted',
      this.getPropsForAppInsights()
    );
  }

  private clearStepTaskSelection() {
    this.$location.search('stepTask', null); // Remove the step task Id from the route.
    this.selectedStepTask = null;
    this.displaySelectedStepTaskDetails = false;
  }

  /*
   * If a step task is cancelled then of any users that has the same step task selected
   * needs to have their view reset as the step task is no longer valid. If the step task
   * that was deleted and not the current selected one then there is nothing to be reset.
   */
  private resetStepTaskSelection(data) {
    if (data && data.stepTaskId && this.selectedStepTask) {
      if (this.selectedStepTask.Id === data.stepTaskId) {
        this.clearStepTaskSelection();
      }
    }
  }

  /*
   * A user could end up here redirected from an email button click, in that case we might have a
   * stepTaskId in the query string. If its is found in the URL then we need to select a StepTask
   * that matches this ID.
   */
  private dynamicallySelectStepTask() {
    // TODO: SS - We should ideally have the name of the query string "stepTask" in a config or somewhere else.
    // Get step task id from query string if it exists.
    const stepTaskIdToSelect = this.$location.search().stepTask;
    if (stepTaskIdToSelect != null) {
      if (this.selectedStep.StepTasks != null) {
        const stepTask = this.selectedStep.StepTasks.find(
          (tsk) => tsk.Id === stepTaskIdToSelect
        );
        if (stepTask != null) {
          this.stepTaskSelectionChanged(stepTask);
        }
      }
    }
  }

  private stepTaskSelectionChanged(stepTask) {
    // Unselect any steps or step tasks first.
    if (this.flow.Steps != null) {
      for (const step of this.flow.Steps) {
        step.IsSelectedStep = false;
        if (step.StepTasks != null) {
          for (const task of step.StepTasks) {
            task.IsSelected = false;
          }
        }
      }
    }

    // Change selection to the new step task.
    stepTask.IsSelected = true;
    this.selectedStepTask = stepTask;
    this.displaySelectedStepTaskDetails = true;
  }

  private injectDynamicDropDownsOptions() {
    // save the NotifyFields property to schema, then in FormGen side, we know which fields need to notify
    this.selectedStep.Schema.fields.forEach((s) => {
      if (s.type !== 'submit') {
        const matchField = this.selectedStep.Fields.find(
          (f) => f.Name === s.name
        );

        if (matchField) {
          if (matchField.NotifyFields) {
            s.notifyFields = matchField.NotifyFields;
          }

          if (s.dataSource === 'database' && matchField.Options) {
            s.options = JSON.parse(matchField.Options);
          } else if (s.dataSource === 'database') {
            s.options = [];
          } else if (s.type === 'table' && matchField.Options) {
            s.options = JSON.parse(matchField.Options);
          }
        }
      }
    });
  }

  private haveIncompleteSteps() {
    return this.flow.Steps.find((s) => s.IsCompleted === 0);
  }

  private unSelectAnyStepTasks() {
    this.clearStepTaskSelection();

    if (this.flow.Steps != null) {
      for (const step of this.flow.Steps) {
        if (step.StepTasks != null) {
          for (const stepTask of step.StepTasks) {
            stepTask.IsSelected = false;
          }
        }
      }
    }
  }

  private onSelectStep(stepId) {
    // The user has clicked on a step so we need to unselect any step tasks.
    this.unSelectAnyStepTasks();

    if (stepId !== this.selectedStep.Id) {
      this.changingStep = true;

      this.saveProgress().then(() => {
        for (const s of this.flow.Steps) {
          s.IsSelectedStep = false;

          if (s.Id === stepId) {
            s.IsSelectedStep = true;
            this.selectedStep = s;
            this.updateDisplayOnDivergeStepsSwitch(this.selectedStep);
            this.injectDynamicDropDownsOptions();

            if ($('li[aria-controls=tabstrip-3]').hasClass('k-state-active')) {
              this.flowinglyDiagramService.generateProcessModel({
                flow: this.flow,
                applyAvatar: false,
                modelCustomArgs: {
                  scrollMode: this.goService.Diagram.DocumentScroll
                }
              });
            }
          }
        }

        this.$timeout(() => {
          this.changingStep = false;
        }, 500);
      });
    }
  }

  private updateDisplayOnDivergeStepsSwitch(selectedStep) {
    if (selectedStep.$data) {
      const fieldFiles = this.fgFileListService.getFilesForStep(
        selectedStep.Id
      );
      for (const field of selectedStep.Fields) {
        if (field.Type.toLowerCase() === 'fileupload') {
          if (fieldFiles) {
            selectedStep.$data[field.Name] = fieldFiles
              .filter((group) => group.fieldName === field.Name)
              .flatMap((group) => group.files)
              .map((file) => file.id)
              .toString();
            field.Value = selectedStep.$data[field.Name];
          } else {
            field.Value = null;
          }
        } else {
          field.Value = selectedStep.$data[field.Name];
        }
      }
    }
  }

  private saveProgress() {
    if (!this.selectedStep || !this.selectedStep.IsWaitingForYou) {
      return this.$q.when();
    }

    const formData = this.stepService.getFormDataWithoutValidating(
      this.selectedStep
    );

    this.runnerFlowsFormatter.encodeFlowFormData(
      formData,
      this.selectedStep.Schema.fields,
      true
    );
    const dataToSave = this.stepService.appendTimezoneOffsetToDates(
      formData,
      this.selectedStep.Schema
    );

    return this.flowApiService.saveFormProgress(
      this.selectedStep.FlowId,
      this.selectedStep.Id,
      dataToSave,
      this.fgFileListService.getFilesForStep(this.selectedStep.Id)
    );
  }

  callBoomiProcess() {
    return this.flowApiService.callBoomiProcess(
      '418b8469-1590-42aa-86bf-205fb01a9cdb',
      'City',
      'Auckland'
    );
  }

  private scrollToTop() {
    this.$timeout(() => {
      const paneRightViewTopMarker = document.getElementById(
        'pane-right-scroll-view-top-marker'
      );
      if (paneRightViewTopMarker) {
        paneRightViewTopMarker.scrollIntoView();
      }
    });
  }

  private getPropsForAppInsights() {
    const {
      FlowIdentifier: flowIdentifier,
      Steps: steps,
      FinalisedReason: finalisedReason,
      PercentageComplete: percentageComplete
    } = this.flow ?? {};
    const selectedStep = steps?.find((step) => step.IsSelectedStep === true);
    const isLastStep = percentageComplete === 100;
    const transitionToStep = isLastStep ? undefined : selectedStep?.Name;
    return {
      flowIdentifier,
      percentageComplete,
      isLastStep,
      transitionToStep,
      finalisedReason
    };
  }
}
class FlowContainer implements angular.IComponentOptions {
  public bindings = {
    flow: '<',
    isMobile: '<', //show mobile view if set
    userId: '<', //userid of the logged in user
    onCommentsClick: '&' //show comments tab on click
  };

  public templateUrl =
    'Client/runner.flow/runner.flow.container/runner.flow.container.tmpl.html';

  public controller = FlowContainerController;
}

angular
  .module('flowingly.runner.flow')
  .component('flowContainer', new FlowContainer());
